const portfolioData = [
  {
    name: "Real Estate",
    image: require("../images/p3.png"),
    link: "https://realestate-homyz.amirmazban.com/",
  },
  {
    name: "Digital Media",
    image: require("../images/p1.png"),
    link: "https://digitalmedia.amirmazban.com/",
  },
  {
    name: "Fit Club",
    image: require("../images/p6.png"),
    link: "https://fitclub.amirmazban.com/",
  },
  {
    name: "Cryptex",
    image: require("../images/p2.png"),
    link: "https://cryptex.amirmazban.com/",
  },
  {
    name: "AppVibe",
    image: require("../images/p10.png"),
    link: "https://appvibe.amirmazban.com/",
  },
  {
    name: "Gamics",
    image: require("../images/p4.png"),
    link: "https://gamics.amirmazban.com/",
  },
  {
    name: "3D Travelo PC",
    image: require("../images/p5.png"),
    link: "https://3dtravelo.amirmazban.com/",
  },
  {
    name: "Movies Site",
    image: require("../images/p7.png"),
    link: "https://lilmovies.amirmazban.com/",
  },
  {
    name: "Fit Club 2",
    image: require("../images/fitclub2.png"),
    link: "https://fitclub2.amirmazban.com/",
  },
  {
    name: "Personal Site",
    image: require("../images/p8.png"),
    link: "https://personalwebsite.amirmazban.com/",
  },
];

export default portfolioData;
